<template>
  <div>
    <div class="icon">
      <i class="el-icon-loading"></i>
      <p>正在登录，请稍等。。</p>
    </div>
  </div>
</template>
<script>
import baseConfig from "~/baseConfig/index.js";

export default {
  data() {
    return {
      code: "",
    };
  },
  methods: {
    async getUser() {
      this.code = this.$route.query.code;
      let token = this.$route.query.access_token;
      if (this.code) {
        let params = {
          identity_type: 4,
          identity_str: this.code,
        };
        params.source = this.PJSource;
        try {
          let res = await this.$store.dispatch("register/wxLogin", params);
          if (res.success) {
            this.wxInfo = res.data;
            if (res.data.is_new == 0) {
              this.getUserInfo(res.data.user_id);
            } else {
              this.$router.push({
                path: "/skipBing",
                query: {
                  unionid: res.data.unionid,
                },
              });
            }
          }
        } catch (e) {}
      }
      if (token) {
        this.getUserTokenInfo(token);
      }
    },
    async getUserTokenInfo(token) {
      try {
        let user_result = await this.$store.dispatch(
          "baseStore/baseSign_getUserTokenInfo",
          {
            access_token: token,
          }
        );
        if (user_result.success) {
          let params = {
            meeting_id: this.$store.state.meeting_id,
            user_id: user_result.data.id,
            company_id: user_result.data.company_id,
          };
          let result = await this.$store.dispatch(
            "baseStore/baseSign_getMeetingEnrollInfo",
            params
          );
          if (result.success) {
            this.$router.push("/");
          }
        }
      } catch (e) {}
    },
    async getUserInfo(id) {
      try {
        let user_result = await this.$store.dispatch(
          "baseStore/baseSign_getUserInfo",
          {
            user_id: id,
          }
        );
        if (user_result.success) {
          let params = {
            meeting_id: this.$store.state.meeting_id,
            user_id: user_result.data.id,
            company_id: user_result.data.company_id,
          };
          let result = await this.$store.dispatch(
            "baseStore/baseSign_getMeetingEnrollInfo",
            params
          );
          if (result.success) {
            this.$router.push("/");
          }
        }
      } catch (e) {}
    },
  },
  mounted() {
    this.getUser();
  },
};
</script>
<style lang="less" scoped>
.icon {
  padding: 300px 0;
  text-align: center;
  i {
    font-size: 30px;
  }
  p {
    font-size: 16px;
    margin-top: 20px;
  }
}
</style>
